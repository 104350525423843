import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Experience`}</h1>
    <h2>{`Tyler Technologies (Formerly MicroPact), Remote`}</h2>
    <h3>{`UX Architect`}</h3>
    <p>{`Performed responsibilities including: UX Architect,  Lead Engineer, UX Researcher, UX Designer, Technical Writer, QA Engineer.`}</p>
    <ul>
      <li parentName="ul">{`Designed, developed, and deployed a product documentation platform utilizing GatsbyJS framework with Markdown into AWS S3 served through Cloudfront. This platform replaced multiple third party documentation applications saving unnecessary cost and unified the division’s documentation in an organized and consistent manner. The platform is used by all divisional employees as well as development partners. I was given a Community Recognition Award for my efforts in going above and beyond in helping to unify different areas of the division.  `}</li>
      <li parentName="ul">{`Established and maintained a design system based on Google’s Material Design. The design system was then propagated out to all professional service and product teams to follow as a standard of client-facing development. `}</li>
      <li parentName="ul">{`Designed a unified application development experience to seamlessly deploy modern web applications. This experience was a major enhancement of the existing Java/Spring solution by allowing the simultaneous creation of a NextJS/React front-end with a upgraded Java/Spring backend communicating with GraphQL. The solution is used for every project the division outputs.`}</li>
      <li parentName="ul">{`Engaged with clients ranging from state and local governments to federal agencies in an Agile Methodology.`}</li>
      <li parentName="ul">{`Utilized automated JavaScript testing with Cypress.`}</li>
      <li parentName="ul">{`Contribute and support the company's React component library based on MUI components. `}</li>
      <li parentName="ul">{`Introduced UX methodologies (storyboarding, wireframing, prototyping, etc) into application and feature development of the core application team and downstream professional service teams. This has increased efficiency in application development by introducing design cycles with the clients which allowed for better expectations of the feature sprint work and closed any gaps from analysis.`}</li>
      <li parentName="ul">{`Engaged with clients to perform a multitude of usability analyses to identify and optimize user experience. `}</li>
      <li parentName="ul">{`Advised and supported web accessibility related issues.`}</li>
      <li parentName="ul">{`Engaged in multiple governance boards to provide standards in design and development across 2000+ employees.`}</li>
      <li parentName="ul">{`Utilize Terraform to manage cloud deployment of UX management applications into AWS.`}</li>
      <li parentName="ul">{`Support ad-hoc requests for development and design needs across the division. Received a Community Recognition Award for my efforts. `}</li>
    </ul>
    <h3>{`Lead Sales Engineer`}</h3>
    <p>{`Performed responsibilities including: Lead Engineer, Technical Project Manager, IT Administrator, Business Analyst, Designer, Solution Architect. `}</p>
    <ul>
      <li parentName="ul">{`Lead a team of engineers and business analysts composed of both onshore and offshore staff.`}</li>
      <li parentName="ul">{`Developed an accelerator platform to optimize prototype output speed and quality.`}</li>
      <li parentName="ul">{`Maintained team application and database servers.`}</li>
      <li parentName="ul">{`Manage parallel project development with constantly competing deadlines.`}</li>
      <li parentName="ul">{`Successfully deployed 100+ solutions totally in over $100M in contracts.`}</li>
      <li parentName="ul">{`Supported contracts & proposals  on responding to technical questions around RFPs & RFQs.`}</li>
      <li parentName="ul">{`Reported directly to the Chief Customer Officer.`}</li>
    </ul>
    <h3>{`Sales Engineer`}</h3>
    <ul>
      <li parentName="ul">{`50+ solutions rapidly prototyped utilizing the company's case management development platform (formerly entellitrak) which is a Java/Spring application backed by an Oracle or MSSQL Server database. `}</li>
      <li parentName="ul">{`Utilized a rapid agile approach to quickly gain feedback and maximize client engagement during the sales/prototyping process.`}</li>
      <li parentName="ul">{`Traveled and presented prototype applications with sales staff while advising on any technical question that may arise during the presentation.`}</li>
      <li parentName="ul">{`Introduced a strong focus in component creation and reusability to streamline prototyping process. `}</li>
    </ul>
    <h2>{`Ozmo (Formerly Modea Incorporated), Blacksburg, VA`}</h2>
    <h3>{`Content Developer`}</h3>
    <ul>
      <li parentName="ul">{`Successfully developed and implemented device support solutions for mobile operators ranging across North America.`}</li>
      <li parentName="ul">{`Users of the support solution included Customer Service Representatives (CSRs) and mobile device end users.`}</li>
      <li parentName="ul">{`Solution implementation is  a combination of emulations and simulations of mobile devices via a web browser.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      